import React from 'react'

export default () => (
    <>
        <h2>
            Questions fréquentes sur l’accusation d’alcool au volant au Québec!
        </h2>
        <h3>
            1. Quelles sont les conséquences immédiates d’une infraction
            d’alcool au volant?{' '}
        </h3>
        <p>
            Une infraction d’alcool au volant ou de conduite avec les facultés
            affaiblies entraîne des conséquences immédiates, avant même que vous
            ne soyez reconnu coupable par un juge. La première conséquence
            immédiate sera la suspension de votre permis de conduire pour{' '}
            <strong>une période de 90 jours</strong>.{' '}
        </p>
        <p>
            Le véhicule peut également être saisi dans certaines circonstances,
            notamment en situation de récidive ou si le taux d’alcoolémie du
            conducteur dépassait le double de la limite permise.{' '}
        </p>
        <h3>
            2. À quelle peine CRIMINELLE faut-il s’attendre pour conduite avec
            les facultés affaiblies?{' '}
        </h3>
        <p>
            Il y a les sanctions administratives (saisie, suspension de permis,
            etc.) mais il y a également les conséquences criminelles reliées à
            l’alcool au volant. Les conséquences pénales de cette infraction
            varient en fonction du taux d’alcool retrouvé dans le sang du
            conducteur et de la récidive :{' '}
        </p>
        <h4>Première infraction de conduite avec les facultés affaiblies :</h4>
        <ul>
            <li>
                <em>Casier judiciaire </em>
            </li>
            <li>
                <em>
                    Amende minimale de 1000$ (Jusqu’à 2000$ selon le niveau
                    d’alcool retrouvé dans le sang){' '}
                </em>
            </li>
            <li>
                <em>
                    Interdiction de conduire de 1 à 3 ans (Peut être réduite
                    avec un antidémarreur){' '}
                </em>
            </li>
        </ul>
        <h4>Deuxième infraction de conduite avec les facultés affaiblies :</h4>
        <ul>
            <li>Casier judiciaire</li>
            <li>Emprisonnement minimal de 30 jours (Maximal de 10 ans)</li>
            <li>Interdiction de conduire entre 3 et 10 ans </li>
            <li>
                Possibilité d’un antidémarreur éthylométrique après 3 mois
                d’interdiction de conduire{' '}
            </li>
        </ul>
        <h4>
            Troisième infraction de de conduite avec les facultés affaiblies :
        </h4>
        <ul>
            <li>Emprisonnement minimal de 120 jours</li>
            <li>
                Interdiction de conduire de 3 ans minimum (Maximal de 10 ans)
            </li>
            <li>
                Possibilité d’un antidémarreur éthylométrique après 6 mois
                d’interdiction de conduire
            </li>
        </ul>

        <h3>
            3. À combien s’élève le montant des amendes pour une infraction
            d’alcool au volant?
        </h3>
        <p>
            Le Code criminel prévoit qu’une première infraction d’alcool au
            volant vient avec une amende minimale de 1000$. Le taux d’alcoolémie
            retrouvé dans le sang a également un impact sur le montant de
            l’amende. Et pour une infraction portant sur le refus d’obtempérer à
            un agent de la paix, le constat d’infraction peut dépasser les
            2000$.{' '}
        </p>
        <p>
            Sachez qu’il ne s’agit que du montant de la contravention. Par
            exemple, des frais de 300$ à 400$ peuvent s’appliquer pour la
            demande d’un nouveau permis à la SAAQ, et vous devrez également
            débourser pour l’installation d’un antidémarreur éthylométrique.{' '}
        </p>
        <h3>4. Qu’arrive-t-il avec le permis de conduire du conducteur?</h3>
        <p>
            Au moment de l’arrestation, le permis de conduire est{' '}
            <strong>automatiquement suspendu pour 90 jours</strong>. Il s’agit
            pour l’instant d’une suspension administrative qui prend effet
            automatiquement auprès de la Société de l’assurance automobile du
            Québec (SAAQ).{' '}
        </p>
        <p>
            Durant cette suspension, aucun permis restreint n’est accordé à
            moins que vous ne contestiez l’infraction devant le tribunal
            administratif du Québec, et ce, avec succès. Il est également
            impossible de faire installer un antidémarreur éthylométrique durant
            cette période de suspension.{' '}
        </p>
        <p>
            Ce délai de 90 jours peut d’ailleurs être prolongé si l’évaluation
            de risque effectuée par la SAAQ démontre un niveau de risque élevé
            de récidive.{' '}
        </p>
        <h3>
            5. En quoi consiste l’évaluation du risque de la Société de
            l’assurance automobile?
        </h3>
        <p>
            L’interdiction de conduire pendant 90 jours ne prend pas fin
            automatiquement. Selon la situation du conducteur, elle peut exiger
            la réussite d’une évaluation du niveau de risque du conducteur.
            Lorsque la SAAQ envoie une lettre exigeant le passage d’une telle
            évaluation, sachez qu’elle s’avère obligatoire.{' '}
        </p>
        <p>
            En général, l’évaluation sommaire de la SAAQ s’adresse uniquement
            aux conducteurs récidivistes (2<sup>ième</sup> infraction ou plus)
            ou aux conducteurs dont le taux d’alcoolémie dépasse le double de la
            limite permise. Elle ne s’applique donc pas automatiquement pour une
            première infraction.{' '}
        </p>
        <p>
            Advenant qu’une telle évaluation vous soit exigée, il est fortement
            recommandé d’engager un avocat avant de s’y soumettre, car un échec
            peut s’avérer lourd de conséquence.{' '}
        </p>
        <h3>6. Qu’arrive-t-il avec le véhicule du conducteur?</h3>
        <p>
            Le véhicule automobile peut être saisi à certaines conditions lors
            d’une infraction d’alcool au volant. S’il s’agit d’une{' '}
            <strong>première infraction</strong> et que le taux d’alcoolémie ne
            dépassait pas le double de la limite permise, aucune saisie du
            véhicule ne sera effectuée, et le permis redeviendra valide au bout
            du 90 jours de suspension.{' '}
        </p>
        <p>
            Advenant qu’il s’agisse d’une 1<sup>ère</sup> infraction, et que le
            conducteur a dépassé la limite d’alcoolémie permise de plus du
            double – soit plus de 160mg – le véhicule sera saisi pour une
            période de 30 jours.{' '}
        </p>
        <p>
            Si l’infraction provient d’un{' '}
            <strong>refus de « souffler dans la baloune »</strong> ou d’un refus
            d’obtempérer à un agent de la paix, une saisie du véhicule pour une
            période de 30 jours d’office, qu’il s’agisse d’une première
            infraction ou d’une récidive.{' '}
        </p>
        <p>
            Dans le cas d’une récidive, le véhicule sera saisi pour une durée de
            90 jours si la première infraction a eu lieu dans les 10 dernières
            années. Sachez que dès qu’un véhicule est remorqué et saisi, c’est
            le conducteur qui acquitte tous les frais reliés à l’entreposage, au
            remorquage et à l’administration.{' '}
        </p>
        <h3>7. Quelles sont les sanctions à envisager en cas de récidive? </h3>
        <p>
            La récidive a pour effet d’aggraver les sanctions à chaque
            infraction d’alcool au volant. Au niveau administratif, la récidive
            implique généralement la saisie automatique du véhicule, une
            suspension de permis de 90 jours (au lieu de 30), l’augmentation des
            amendes imposées et l’obligation de suivre le Programme d’évaluation
            et du risque de conduite avec les facultés affaiblies.{' '}
        </p>
        <p>
            De plus, une seconde infraction d’alcool au volant est accompagnée
            d’une peine d’emprisonnement minimale de 30 jours si la personne est
            reconnue coupable devant le juge. Les infractions subséquences (plus
            de 2 récidives) viennent avec une période d’emprisonnement minimale
            de 120 jours.{' '}
        </p>
        <h3>
            8. Qu’arrive-t-il si vous refusez de souffler ou de fournir un
            échantillon d’haleine?{' '}
        </h3>
        <p>
            Refuser de souffler dans l’ivressomètre en espérant se sauver des
            sanctions, c’est une très mauvaise idée! Ce refus entraîne
            automatiquement de sérieuses conséquences pour le conducteur, à
            commencer par une amende de 2000$, la perte du permis pour 90 jours,
            la saisie du véhicule pour 30 jours, et ce, avant même qu’un verdict
            criminel ne soit rendu.{' '}
        </p>
        <p>
            Le <em>Code criminel</em> prévoit une interdiction de conduire de 1
            an pour toute personne reconnue coupable de cette infraction, tandis
            que le <em>Code de la sécurité routière</em> prévoit une suspension
            de permis pouvant aller jusqu’à 3 ans.
        </p>
        <p>
            {' '}
            La pose d’un antidémarreur éthylométrique pour une période de 2 ans
            peut également être exigée, et le conducteur sera contraint
            d’adhérer au Programme d’évaluation des risques de conduite avec les
            facultés affaiblies. Et ça, c’est sans parler du fait que le
            conducteur sera désormais titulaire d’un casier judiciaire!{' '}
        </p>
        <h3>
            9. Quel moyen de défense peut être soulevée contre une accusation
            d’alcool au volant?
        </h3>
        <p>
            Plusieurs moyens de défense peuvent être soulevés pour contrer une
            accusation d’alcool au volant. On peut notamment invoquer une
            utilisation non-conforme de l’Appareil de détection (ADA), un délai
            dans le prélèvement de l’échantillon d’haleine, ou encore, l’absence
            de motif raisonnable de vous faire souffler dans l’alcootest.{' '}
        </p>
        <p>
            Le non-respect des droits du conducteur – dont le droit au silence
            ou aux services d’un avocat – peut également constituer une défense
            valide contre une accusation d’alcool au volant. Le rôle de l’avocat
            criminaliste est de cerner la bonne défense en fonction de la
            situation propre au conducteur.{' '}
        </p>
        <h3>
            10. Cannabis et autres drogues au volant : les conséquences
            sont-elles similaires à l’alcool?
        </h3>
        <p>
            Les conséquences d’une accusation de conduite avec les facultés par
            la drogue sont les mêmes que celles réservées aux infractions
            d’alcool au volant. Toutefois, ce sont les barèmes qui changent en
            fonction de la substance.{' '}
        </p>
        <p>
            Pour qu’une personne soit reconnue coupable de conduite avec les
            facultés affaiblies, elle doit avoir :{' '}
        </p>
        <ul>
            <li>
                <em>
                    5ng de THC par ml de sang dans les 2 heures suivant la
                    conduite (Pour le cannabis)
                </em>
            </li>
            <li>
                <em>
                    5mg de GHB par litre de sang dans les 2 heures suivant la
                    conduite{' '}
                </em>
            </li>
            <li>
                <em>
                    Tout niveau détectable de drogue (Cocaïne, LSD,
                    méthamphétamine, etc.)
                </em>
            </li>
        </ul>
        <p>
            Les conséquences pour une infraction reliée aux drogues sont
            similaires à l’alcool, et des moyens de défense similaires peuvent
            être invoqués pour obtenir un verdict de non-culpabilité.{' '}
        </p>
        <h3>
            11. Devrez-vous installer un antidémarreur éthylométrique sur votre
            véhicule pour obtenir un permis de conduire restreint?{' '}
        </h3>
        <p>
            Le permis restreint permet aux conducteurs de conduire leur véhicule
            pendant une période d’interdiction si celui-ci est muni d’un
            antidémarreur éthylométrique. Cet appareil oblige le conducteur à
            souffler dans l’antidémarreur pour « confirmer » l’absence d’alcool
            dans le sang.{' '}
        </p>
        <p>
            Pour obtenir un permis restreint, il est nécessaire d’attendre la
            fin de la période d’interdiction absolue, de signer une entente
            auprès de l’organisme « Smart Start Québec » (laquelle fournit les
            antidémarreurs éthylométriques), de faire installer ce même
            antidémarreur et surtout, de respecter toutes les conditions
            assorties au permis restreint. Parmi ces conditions, on retrouve
            notamment l’interdiction de conduire avec toute trace d’alcool dans
            le sang.
        </p>
        <h3>
            12. Qu’est-ce qu’une accusation de garde et contrôle d’un véhicule
            en état d’ébriété?
        </h3>
        <p>
            L’accusation de garde et contrôle d’un véhicule en état d’ébriété
            s’applique lorsqu’une personne se trouve à l’intérieur de son
            véhicule alors qu’elle est intoxiquée par l’alcool ou la drogue. Une
            telle infraction ne requiert même pas que le véhicule soit en
            marche, ni que le conducteur soit derrière le volant (il arrive même
            qu’il soit endormi).{' '}
        </p>
        <p>
            On devra seulement prouver que la personne en état d’ébriété
            détenait la garde et le contrôle du véhicule, et ce, en dépassant la
            limite d’alcool permise. Évidement, il existe des moyens de défense
            contre une telle accusation, lesquels s’appliqueront principalement
            lorsqu’il n’existait aucun risque réel que le véhicule soit mis en
            marche.{' '}
        </p>
        <p>
            L’emplacement du véhicule, le fait de ne pas avoir les clés et
            d’être assis sur le siège arrière sont quelques exemples d’éléments
            soulevés pour contester l’accusation de garde et contrôle.{' '}
        </p>
        <h3>
            13. À quel moment faut-il engager un avocat dans une situation
            d’alcool au volant?{' '}
        </h3>
        <p>
            Dès le moment de l’arrestation par les policiers, il est essentiel
            de contacter un avocat spécialisé en matière d’alcool au volant.
            Prévalez-vous de votre droit de garder le silence, et tournez-vous
            sans délai vers un avocat; il s’agit d’un droit fondamental que vous
            devez exercer.{' '}
        </p>
        <p>
            Votre avocat s’assurera d’analyser les chefs d’accusation et
            d’élaborer une défense adaptée au type d’accusation de conduite avec
            facultés affaiblies dont vous êtes la cible. Contacter un avocat
            sans délai s’avère d’autant plus important s’il s’agit d’une seconde
            infraction d’alcool au volant de votre part, car les conséquences
            peuvent s’avérer drastiques.{' '}
        </p>
        <h3>
            14. Est-il possible d’éviter un casier judiciaire après avoir
            conduit en état d’ébriété?
        </h3>
        <p>
            Tout à fait! Pour ce faire, vous devrez engager un avocat afin
            d’être acquitté du chef d’accusation porté contre vous. Il n’est pas
            possible d’obtenir l’absolution pour une accusation d’alcool au
            volant, alors votre avocat devra obtenir un verdict de
            non-culpabilité afin d’éviter les conséquences d’un dossier
            criminel.{' '}
        </p>
        <h3>
            15. Comment choisir le bon avocat pour se défendre contre une
            accusation d’alcool au volant?{' '}
        </h3>
        <p>
            Les avocats en droit criminel sont les spécialistes les mieux placés
            pour vous représenter lors d’une accusation de conduite avec les
            facultés affaiblies. Mais plus encore, certains avocats
            criminalistes se spécialisent en matière d’alcool au volant et de
            conduite avec les facultés affaiblies en raison du grand volume
            d’infractions de la sorte au Québec.{' '}
        </p>
        <p>
            Opter pour un tel spécialiste mettra toutes les chances de votre
            côté d’obtenir le verdict de non-culpabilité que vous recherchez.{' '}
        </p>
    </>
)
