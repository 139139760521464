import React from 'react'
import { Grid } from '@material-ui/core'
import { FormatQuote, Star } from '@material-ui/icons'
import styled from 'styled-components'

export const ColoredStar = styled(Star)`
    color: #ffd500;
`

const TestimonyWrapper = styled(Grid)`
    color: black;

    padding: 20px;
    border-radius: 4px;
    box-shadow: 5px 3px 14px 1px rgb(0 0 0 / 26%);
    background: white;

    margin: 0 12px 20px 0;
    @media (max-width: 667px) {
        margin: 0 0px 20px 0;
    }
`

const TestimoniesWrapper = styled(Grid)`
    max-width: 1160px;
    margin: 0 auto 0 -12px;
    img {
        width: 60px;
    }
    display: flex;
    flex-flow: column wrap;
    max-height: 400px;
    @media (max-width: 667px) {
        margin: 0 auto;
        flex-flow: row wrap;
        max-height: initial;
    }
`

const Testimony = ({ name, text, imgSrc }) => {
    return (
        <TestimonyWrapper item container xs={12} sm={4} md={4}>
            <Grid item xs={12}>
                <span style={{ fontSize: '16px', fontWeight: 600 }}>
                    {name}
                </span>
                <br />
                <ColoredStar />
                <ColoredStar />
                <ColoredStar />
                <ColoredStar />
                <ColoredStar />
            </Grid>
            <Grid item xs={12}>
                {text}
            </Grid>
        </TestimonyWrapper>
    )
}

const Testimonies = () => {
    return (
        <TestimoniesWrapper container>
            <Testimony
                name="G. Côté"
                text="L’avocat suggéré par JuriGo était spécialisé en faculté affaiblie. Très content du contact. "
            />
            <Testimony
                name="A. Tremblay"
                text="Un avocat m’a appelé dans l’heure suivant ma demande. Ça a été très rapide."
            />
            <Testimony
                name="P. Girard"
                text="Mon dossier me stressait beaucoup. Merci à votre équipe pour la mise en relation rapide."
            />

            <Testimony
                name="L. Arnaud"
                text="Continuez votre bon travail. Ça m’a beaucoup aidé."
            />
            <Testimony
                name="K. Roy"
                text="J’ai été pris en charge par le bon avocat. Je suis confiant pour la suite. Merci!"
            />
        </TestimoniesWrapper>
    )
}

export default Testimonies
